import * as React from 'react'

import { Page, Container, Typography, Link } from '~/components'
import IndexLayout from '~/layouts'

const NotFoundPage = () => (
  <IndexLayout>
    <Page>
      <Container>
        <Typography variant="h1">404: Page not found.</Typography>
        <Typography>
          You&apos;ve entered the void,{' '}
          <Link to="/">here&apos;s a way out.</Link>
        </Typography>
      </Container>
    </Page>
  </IndexLayout>
)

export default NotFoundPage
